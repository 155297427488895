import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { graphql } from 'gatsby'
import ThemeContext from "../context/ThemeContext"
import Layout from '../components/Layout'
import FeatureBlock from '../components/FeatureBlock'

export class IndexPageTemplate extends React.Component {
  activeIndex = 0
  lastScroll = 0
  isScrolling = false
  scrollTo(index) {
    this.activeIndex = 0
  }
  onWheel(e) {
    if (this.isScrolling) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }
  onScroll(e) {
      e.preventDefault()
      const direction = window.scrollY > this.lastScroll ? 1 : -1
      const slideList = document.getElementsByClassName('cFeatureBlock')
      const slides = Array.prototype.slice.call( slideList )
      const inView = slides.filter((slide) => {
        const bounding = slide.getBoundingClientRect()
        return bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      })
      const height = slides[0].offsetHeight
      const width = slides[0].offsetWidth
      if (width > 768) {
      const minScrollDistance = height / 3
      const scroll = setTimeout(() => {
        if (Math.abs(window.scrollY && !this.isScrolling)) {
          this.isScrolling = true
          const reverseSlideIndex = (slides.length - inView.length) -1
          const minScroll = (height * reverseSlideIndex) + minScrollDistance
          setTimeout(() => {
            clearTimeout(scroll)
            this.isScrolling = false
              if (direction === 1) {
                if (window.scrollY >= minScroll) {
                  inView[0].scrollIntoView({behavior: "smooth"})
                }
              } else if (direction === -1) {
                slides[reverseSlideIndex].scrollIntoView({behavior: "smooth"})
              }
          }, 500)
        }
      }, 50)
      this.lastScroll = window.scrollY
      return false
    }
  }
  componentDidMount() {
    this.context.showNav()
    if (typeof window !== 'undefined') {
      this.lastScroll = window.scrollY
      // window.addEventListener('scroll', this.onScroll.bind(this), { passive: false })
      // window.addEventListener('wheel', this.onWheel.bind(this), { passive: false })
      // window.addEventListener('DOMMouseScroll', this.onWheel.bind(this), { passive: false })
    }
  }
  render () {
    const { locations, locationId } = this.props
    return (
      <div className="cIndex">
        {locations && locations.length && locations[0] && locations[0].node && locations[0].node.fields && locations[0].node.fields.featuredLocations && locations[0].node.fields.featuredLocations.filter(l => l && l.location && l.location.fields /*&& l.location.fields.stories && l.location.fields.stories.length*/).map((item, idx) => (
          <FeatureBlock
            key={`${kebabCase(item.location.frontmatter.title)}-${idx}`}
            featureBlock={item}
            enableExpand={item.location.id === locationId}
          />
        ))}
      </div>
    )
  }
}

IndexPageTemplate.contextType = ThemeContext

IndexPageTemplate.propTypes = {
  locationId: PropTypes.string,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        fields: PropTypes.shape({
          id: PropTypes.string,
          featuredLocations: PropTypes.arrayOf(
            PropTypes.shape({
              image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
              location: PropTypes.shape({
                fields: PropTypes.shape({
                  neighborhood: PropTypes.shape({
                    fields: PropTypes.shape({
                      city: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                          title: PropTypes.string
                        })
                      })
                    })
                  }),
                  slug: PropTypes.string,
                  stories: PropTypes.arrayOf(
                    PropTypes.shape({
                      frontmatter: PropTypes.shape({
                        title: PropTypes.string,
                        description: PropTypes.string,
                        featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
                      }),
                      fields: PropTypes.shape({
                        slug: PropTypes.string
                      })
                    })
                  )
                }),
                frontmatter: PropTypes.shape({
                  title: PropTypes.string,
                  description: PropTypes.string
                })
              }),
              overlay: PropTypes.string,
              text: PropTypes.string,
              titleColor: PropTypes.string
            })
          )
        })
      })
    })
  )
}

const IndexPage = ({ data, location }) => {
  const { edges } = data.allMarkdownRemark
  console.log('Data!', edges)
  const locationId = location && location.state && location.state.locationId ? location.state.locationId : ''
  if (location && location.state && location.state.locationId && typeof window !== 'undefined' && window.history) {
    location.state.locationId = ''
    window.history.replaceState({locationId: ''}, document.title);
  }
  return (
    <Layout>
      <IndexPageTemplate
        locations={edges}
        locationId={locationId}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    })
  })
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "index-page"}}}) {
      edges {
        node {
          id
          fields {
            slug
            featuredLocations {
              image {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 75) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              location {
                fields {
                  neighborhood {
                    fields {
                      city {
                        frontmatter {
                          title
                        }
                      }
                    }
                  }
                  slug
                  stories {
                    frontmatter {
                      description
                      featuredimage {
                        childImageSharp {
                          fluid(maxWidth: 1920, quality: 75) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                      title
                    }
                    fields {
                      slug
                    }
                  }
                }
                frontmatter {
                  title
                }
                id
              }
              overlay
              text
              titleColor
            }
          }
        }
      }
    }
  }
`
