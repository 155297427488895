import React from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import ExpandBlock from '../components/ExpandBlock';

export default class FeatureBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    }
    this.toggleExpand = this.toggleExpand.bind(this);
    this.handleScrollToElement = this.handleScrollToElement.bind(this);
    this.expandBlockRef = React.createRef()
    this.featureBlockRef = React.createRef()
  }
  toggleExpand() {
    this.setState(
      { active: !this.state.active }
    )
    if (this.state.active) {
      setTimeout(() => {
        this.setState({
          active: false
        })
      }, 200)
    } else {
      this.setState({
        active: true
      })
    }
    this.handleScrollToElement()
  }

  handleScrollToElement() {
    if (!this.state.active && this.expandBlockRef) {
      setTimeout(() => {
        this.expandBlockRef.current.scrollIntoView({behavior: "smooth", block: "start"})
      }, 200)
    } else {
      this.featureBlockRef.current.scrollIntoView({behavior: "smooth", block: "start"})
    }
  }

  componentDidMount() {
    if (this.props.enableExpand) {
      this.toggleExpand()
    }
  }

  render() {
    let city = ''
    const active = this.state.active
    const { featureBlock } = this.props
    const { image, location, overlay, text, titleColor } = featureBlock
    const title = location.frontmatter.title

    if (location.fields && location.fields.neighborhood && location.fields.neighborhood.fields && location.fields.neighborhood.fields.city && location.fields.neighborhood.fields.city.frontmatter && location.fields.neighborhood.fields.city.frontmatter.title) {
      city = location.fields.neighborhood.fields.city.frontmatter.title
    }
    return (
      <div ref={this.featureBlockRef}>
        <div className="cFeatureBlock">
          <div
            className={`cFeatureBlock-banner ${overlay}`}
          >
          {image && image.childImageSharp && image.childImageSharp.fluid ? 
            <Img
                fluid={image.childImageSharp.fluid}
                alt={title}
                className="cFeatureBlock-image"
                onClick={this.toggleExpand}
                />
           : null}
            <div className="cFeatureBlock-inner">
              { false && <div className="cFeatureBlock-subtitle" onClick={this.toggleExpand}>{city}</div>}
              <div
                style={{ color: titleColor ? titleColor : null }}
                className="cFeatureBlock-title" onClick={this.toggleExpand}>{title}
              </div>
            </div>
            <button className="cFeatureBlock-btn link" onClick={this.toggleExpand}>Read Here</button>
          </div>
        </div>
        <div className={`cExpandBlock ${active ? 'active' : ''}`} ref={this.expandBlockRef}>
          <ExpandBlock
            active={active}
            color={titleColor}
            description={text}
            location={location}
            overlay={overlay}
            toggleExpand={this.toggleExpand}
          />
        </div>
      </div>
    )
  }
}

FeatureBlock.propTypes = {
  enableExpand: PropTypes.bool,
  featureBlock: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    location: PropTypes.shape({
      fields: PropTypes.shape({
        neighborhood: PropTypes.shape({
          fields: PropTypes.shape({
            city: PropTypes.shape({
              frontmatter: PropTypes.shape({
                title: PropTypes.string
              })
            })
          })
        }),
        slug: PropTypes.string,
        stories: PropTypes.arrayOf(
          PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string,
              description: PropTypes.string,
              featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string
            })
          })
        )
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string
      }),
      id: PropTypes.string
    }),
    overlay: PropTypes.string,
    text: PropTypes.string,
    titleColor: PropTypes.string
  })
}
