import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import StoryCard from './Story/Card'

export default class ExpandBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: 3
    }
    this.updateDimensions = this.updateDimensions.bind(this)
    this.updateOffset = this.updateOffset.bind(this)
    this.onArrowClick = this.onArrowClick.bind(this)
  }
  updateOffset(n) {
    this.setState({
      offset: n
    })
  }
  onArrowClick(length, direction) {
    const offset = this.state.offset
    if (offset === 0 && direction === 'next') {
      this.updateOffset(this.state.limit)
    } else if (offset < (length - 1) && direction === 'next') {
      this.updateOffset(offset + 1)
    } else if (offset < (length - 1) && direction === 'prev') {
      this.updateOffset(offset - 1)
    } else {
      this.updateOffset(0)
    }
  }
  updateDimensions() {
    if (typeof window === 'undefined' || !window) {
      return
    }
    let limit = 3
    if (window.outerWidth < 768) {
      limit = 1
    } else if (window.outerWidth < 1200) {
      limit = 2
    } else {
      limit = 3
    }
    this.setState({
      limit
    })
  }
  componentWillMount() {
    this.updateDimensions()
  }
  componentDidMount() {
    if (typeof window === 'undefined' || !window) {
      return
    }
    window.addEventListener('resize', this.updateDimensions)
  }
  componentWillUnmount() {
    if (typeof window === 'undefined' || !window) {
      return
    }
    window.removeEventListener('resize', this.updateDimensions)
  }
  render() {
    const { color, description, location, overlay, toggleExpand } = this.props
    const cardWidth = 100 / (this.state.limit + 0.25)
    return (
        <div className="cExpandBlock-inner">
          {description ? (
          <p className="cExpandBlock-text">{description}</p>
          ) : null}
          {/* <div className="cExpandBlock-link">
            <Link to={location.fields.slug} className="cExpandBlock-btn link">View on Map</Link>
          </div> */}

          <div className="cExpandCarousel">
            <div className="cExpandCarousel-inner">
              {location.fields.stories ? location.fields.stories.map((story, idx) =>
                (
                  <div
                    key={idx}
                    className={`cExpandCarousel-item ${idx <= (this.state.offset + this.state.limit - 1) ? 'active' : ''}`}
                    style={{
                      marginLeft: location.fields.stories.length === 1 ? 'auto' : (idx < (this.state.offset - this.state.limit + 1) ? `${-cardWidth}%` : '0'),
                      width: `${cardWidth}%`
                    }}
                  >
                    <StoryCard story={story} color={color} overlay={overlay} locationId={location.id} onFocus={() => {this.updateOffset(idx)}} />
                  </div>
                )
              ) : null}
                {location.fields.stories && location.fields.stories.length > this.state.limit ? (
                  <button className="cExpandCarousel-arrow next" onClick={() => this.onArrowClick(location.fields.stories.length, 'next')}>
                    <img src="/img/arrow-right.png" alt="right arrow" />
                  </button>
                ) : null}
                {location.fields.stories && location.fields.stories.length && this.state.offset > 0 ? (
                  <button className="cExpandCarousel-arrow prev" onClick={() => this.onArrowClick(location.fields.stories.length, 'prev')}>
                    <img src="/img/arrow-left.png" alt="left arrow" />
                  </button>
                ) : null}
            </div>
          </div>
          <div className="cExpandStories">
            <div className="cExpandStories-inner">
              {location.fields.stories ? location.fields.stories.map((story, idx) =>
                (
                  <div key={idx} className="cExpandStories-story">
                    <StoryCard story={story} color={color} overlay={overlay} locationId={location.id} onFocus={() => {this.updateOffset(idx)}} />
                  </div>
                )
              ) : null}
            </div>
          </div>
          <div className="cExpandBlock-link">
            <button className="cExpandBlock-btn cExpandBlock-btn--close link" onClick={toggleExpand}>Close</button>
          </div>
        </div>
    )
  }
}

ExpandBlock.propTypes = {
  color: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.shape({
    fields: PropTypes.shape({
      neighborhood: PropTypes.shape({
        fields: PropTypes.shape({
          city: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string
            })
          })
        })
      }),
      slug: PropTypes.string,
      stories: PropTypes.arrayOf(
        PropTypes.shape({
          frontmatter: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
          }),
          fields: PropTypes.shape({
            slug: PropTypes.string
          })
        })
      )
    }),
    frontmatter: PropTypes.shape({
      title: PropTypes.string
    }),
    id: PropTypes.string
  }),
  overlay: PropTypes.string,
  toggleExpand: PropTypes.func,
}
